// Default theme
import '@splidejs/react-splide/css';

import * as React from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import YouTubePlayer from 'react-player/youtube';
import styled from 'styled-components';

import iconCircle from 'stile-shared/assets/icons/icon_circle.svg';
import iconCircleTick from 'stile-shared/assets/icons/icon_circle_tick.svg';
import iconRightArrow from 'stile-shared/assets/icons/icon_right_arrow.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import { Slider } from 'stile-shared/src/components/2024/common/Slider';
import { Grid } from 'stile-shared/src/components/2024/layout/Grid';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import { TopSection } from 'stile-shared/src/components/2024/layout/TopSection';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const StileOregonLayout = styled<typeof PageLayout>(PageLayout)``;

const OregonTopSection = styled<typeof TopSection>(TopSection)`
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
  .content {
    position: relative;
    .grid-layout {
      padding-top: 80px;
      padding-bottom: 175px;
      @media (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 45px;
      }
      > .gatsby-image-wrapper {
        top: 100px;
        grid-column: 7 / span 6;
        z-index: 0;
        gap: 0;
        padding: 0;

        @media (max-width: 768px) {
          grid-column: span 12;
          grid-row: 3;
          top: auto;
          margin-top: 40px;
        }
      }
    }

    @keyframes hearts {
      0% {
        opacity: 0;
        transform: translate(0, 0%) rotate(45deg);
      }
      20% {
        //show and hint at moving
        opacity: 0.8;
        transform: translate(0, -20%) rotate(45deg);
      }
      100% {
        opacity: 0;
        transform: translate(0, -1000%) rotate(45deg); //Big hearts move faster
      }
    }
    .hearts {
      position: relative;
      > .particle {
        opacity: 0;
        position: absolute;
        background-color: var(--stile-green-1);
        animation: hearts 3s ease-in infinite;
        &:before,
        &:after {
          position: absolute;
          content: '';
          border-radius: 100px;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--stile-green-1);
        }
        &:before {
          transform: translateX(-50%);
        }
        &:after {
          transform: translateY(-50%);
        }
      }
    }
  }
`;

const AlignedSection = styled.div`
  background-color: var(--white);
  .content {
    padding: 150px 0;
    @media (max-width: 768px) {
      padding: 60px 0 60px 0;
    }
    .grid-layout {
      .left,
      .right {
        position: relative;
        .gatsby-image-wrapper {
          border-radius: 32px;
          aspect-ratio: 1;
        }

        h2,
        p {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;
        }

        .--callout {
          width: 140px;
          height: 140px;
          position: absolute;
          top: -70px;
          right: -70px;
          border-radius: 50%;
          background-color: var(--stile-green-1);
          color: var(--stile-grey-8);
          padding: 24px 14px 40px 15px;
          strong {
            font-family: 'More Sugar';
            font-size: 63.83px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -2.553px;
          }
          span {
            display: block;
            text-align: center;
            font-size: 10.426px;
            font-weight: 600;
            line-height: 15.638px; /* 150% */
          }

          @media (max-width: 768px) {
            top: -35px;
            right: 35px;
            width: 100px;
            height: 100px;
            padding: 15px 8px 28px 9px;
            strong {
              font-size: 47.324px;
              line-height: normal;
              letter-spacing: -1.893px;
            }
            span {
              font-size: 7px;
              line-height: 12.287px; /* 153.59% */
            }
          }
        }

        .--buttons {
          a:first-child {
            margin-bottom: 10px;
          }

          @media (max-width: 768px) {
            a {
              width: 100%;
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
      .left {
        grid-column: 1 / span 6;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
          padding-bottom: 30px;
        }
      }
      .right {
        grid-column: 8 / span 5;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
        }
      }
    }
  }
`;

const HearFromSection = styled.div<{ $backgroundColor?: string; $color?: string }>`
  background-color: var(--stile-grey-8);
  color: var(--white);

  dialog {
    background-color: var(--stile-grey-8);
    opacity: 0.9;
    padding: 0;
    width: 90vw;
    height: 90vh;
    ::backdrop {
      background-color: var(--stile-grey-8);
      opacity: 0.9;
    }
  }

  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .grid-layout {
      .left,
      .right {
        position: relative;
        .gatsby-image-wrapper {
          border-radius: 32px;
          border: 1px solid var(--stile-green-1);
        }
        .playButton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        h2,
        p {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;

          @media (max-width: 768px) {
            margin-bottom: 0px;
          }
        }
      }
      .left {
        grid-column: 1 / span 5;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
          padding-bottom: 30px;
        }
      }
      .right {
        grid-column: 8 / span 6;
        cursor: pointer;
        background-color: transparent;
        border: none;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
        }
      }
    }
  }
`;

const RealWorldSection = styled<typeof Section>(Section)`
  h2 {
    margin: 0 0 32px 0 !important;
    @media (max-width: 768px) {
      margin-bottom: 18px !important;
    }
  }

  @media (max-width: 768px) {
    padding: 60px 0 0 0;
    .content {
      padding-top: 0 !important;
    }
  }

  .--subheader {
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      margin-bottom: 40px;
    }
  }

  .showcase-content {
    grid-column: span 12;
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
  .grid-layout {
    @media (max-width: 768px) {
      margin-top: 0 !important;
      padding-bottom: 70px;
    }
  }
`;

const ScienceTestSection = styled<typeof Section>(Section)`
  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .grid-layout {
      margin-top: 0 !important;
    }

    h2 {
      margin: 0 0 32px 0 !important;
    }

    .--subheader {
      grid-column: 4 / span 6;
      text-align: center;
      margin-bottom: 120px;
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
        margin-bottom: 60px;
      }
    }

    .--side-by-side {
      grid-column: span 12;
      display: flex;
      flex-direction: row;
      gap: 80px;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 40px;
      }

      .--left,
      .--right {
        width: 35%;
        background-color: var(--white);
        border-radius: 32px;
        position: relative;
        padding: 80px 40px 50px 50px;

        box-shadow: 0 0 10px 10px #fff;

        @media (max-width: 768px) {
          width: 100%;
          box-shadow: none;

          .gatsby-image-wrapper {
            margin-top: 30px;
          }
        }

        .h8 {
          padding: 0;
          margin: 0;
        }
        .--rounded-heading {
          border-radius: 32px;
          color: var(--white);
          background-color: var(--stile-grey-8);
          position: absolute;
          display: inline-flex;
          padding: 9px 20px 10px 20px;
          justify-content: center;
          align-items: center;

          // position it top center but half way up itself
          top: 8%;
          left: 40%;
          transform: translate(-30%, -150%);
        }
        p {
          margin: 10px 0 30px 0;
          @media (max-width: 768px) {
            margin-bottom: 0;
          }

          padding: 0;
          ol {
            list-style: upper-alpha;
            padding-left: 20px;
            margin-top: 30px;
            margin-bottom: 0;
            li {
              padding-left: 20px;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          ul {
            padding-left: 0px;
            margin-top: 40px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 15px;
            li {
              display: flex;
              background: url(${iconCircle}) no-repeat left top;
              padding-left: 30px;
            }
          }
        }
      }

      .--left {
      }

      .--right {
        .--rounded-heading {
          background-color: var(--stile-green-2);
        }
      }
    }
  }
`;

const ExpertAssistance = styled.div`
  padding: 150px 0;
  background-color: var(--white);
  @media (max-width: 768px) {
    padding: 60px 0;
  }
  .content {
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 531.667px 149.028px 0px rgba(0, 0, 0, 0),
      0px 339.944px 136.139px 0px rgba(0, 0, 0, 0.01),
      0px 191.722px 115.194px 0px rgba(0, 0, 0, 0.03), 0px 85.389px 85.389px 0px rgba(0, 0, 0, 0.04),
      0px 20.944px 46.722px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
    }

    > div {
      padding: 60px;
      border-radius: 32px;
      border: 0.806px solid var(--stile-grey-1);
      background-color: var(--stile-grey-1);
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      gap: 52px;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 25px 25px 30px 25px;
      }

      .--left,
      .--right {
        flex-basis: 50%;
        @media (max-width: 768px) {
          flex-basis: 100%;
        }
      }

      .--left {
        justify-items: center;
        align-content: center;

        .--headings {
          display: flex;
          flex-direction: column;
          gap: 22px;
          @media (max-width: 768px) {
          }
          .h9 {
            margin: 0;
            padding: 0;
          }
          h4 {
            margin: 0;
            padding: 0;
          }
        }
        .--copy {
          display: flex;
          flex-direction: column;
          gap: 10px;
          p {
            width: 71%;

            @media (max-width: 768px) {
              width: 100%;
            }
          }
          .button {
            div {
              margin-left: 6px;
            }
          }
        }
      }

      .--right {
        .gatsby-image-wrapper {
          border-radius: 32px;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
`;

const testimonials = [
  {
    name: 'Jim Anderson',
    school: 'H.B. Lee Middle School',
    quote:
      'Stile allows us to be flexible and change and adapt the materials so it reflects better what the students need.',
  },
  {
    name: 'Abby Richardson',
    school: 'St Agatha Catholic School',
    quote:
      'There are just so many different parts of the brain that Stile accesses with its question types and it all just builds together; it’s one curriculum but it’s reaching all of my kids that have these different learning styles.',
  },
  {
    name: 'Jenn Greenleaf',
    school: 'H.B. Lee Middle School',
    quote:
      'One of the things I like about Stile is that there is some cultural responsiveness. My middle school is very diverse and we have kids from all over the planet.',
  },
  {
    name: 'Mike Hall',
    school: 'Astoria Middle School',
    quote:
      'The learning targets are there, all the assessments are built in so it’s just crazy efficient. A lot of us like to develop our own curriculum but it’s really nice to have a foundation to build it from instead of feeling stressed.',
  },
  {
    name: 'Sam McLeod',
    school: 'Astoria Middle School',
    quote:
      'The other ones we looked at, the effort to get it going and implemented would have been a huge stress and transition... Whereas Stile, once Julianna got us the login you were going.',
  },
  {
    name: 'Christina De Anda',
    school: 'Armand Larive Middle School',
    quote:
      "We have a high demographic of newcomer students that English is their second or third language. I think this is really able to maximize their ability to understand science. We didn't always have translation last year.",
  },
  {
    name: 'Steve Anderson',
    school: 'H.B. Lee Middle School',
    quote: 'Right out of the box it has good content, and engaging content for the students.',
  },
  {
    name: 'Abby Richardson',
    school: 'St Agatha Catholic School',
    quote:
      'Before Stile there was never a curriculum I really liked. I would pull pieces from here, pieces from there, and I felt like I was almost having to reinvent the wheel every year. I’ve never had labs work so well like Stile labs do.',
  },
  {
    name: 'Dara Brennan',
    school: '**NEED SCHOOL NAME**',
    quote: 'My teachers are laughing, they never laugh!',
  },
  {
    name: 'Spencer **NEED LAST NAME**',
    school: '**NEED SCHOOL NAME**',
    quote: 'With Stile, I’ll daydream less about quitting teaching.',
  },
  {
    name: 'Mike Hall',
    school: 'Astoria Middle School',
    quote:
      'The learning targets are there, all the assessments are built in so it’s just crazy efficient. A lot of us like to develop our own curriculum but it’s really nice to have a foundation to build it from instead of feeling stressed.',
  },
  {
    name: 'Olivia Bailey',
    school: 'Astoria Middle School',
    quote:
      'We like how customizable it is as well as the immediate feedback and the formative assessments. It’s just really user friendly for teachers and it saves us a ton of time.',
  },
];

function Testimonial({
  name,
  school,
  quote,
  ...props
}: { name: string; school: string; quote: string } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <p className="quote">{quote}</p>
      <div>
        <h6 className="h8">{name}</h6>
        <h6 className="h8">{school}</h6>
      </div>
    </div>
  );
}

const TestimonailsSection = styled.div`
  padding-bottom: 0;
  background-color: transparent;

  // break out from the container and go full viewport width
  margin-left: calc(50% - 50vw);
  width: 100vw;

  .splide {
    background-color: transparent;
    .splide__track {
      padding-bottom: 200px;
      background-color: transparent;
    }
    &:nth-child(2) {
      margin-top: -178px;
      .splide__track {
        padding-bottom: 100px;

        @media (max-width: 768px) {
          padding-bottom: 60px;
        }
      }
    }
  }

  &.mobile {
    display: none;
  }
  @media (max-width: 768px) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

const StyledTestimonial = styled<typeof Testimonial>(Testimonial)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 28px 25px 28px;
  align-items: flex-start;
  border-radius: 32px;
  border: 1px solid var(--stile-green-1);
  background: var(--white);

  height: 200px;
  width: 500px;

  @media (max-width: 768px) {
    width: 95%;
    height: 250px;
  }

  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.01), 0px 20px 20px 0px rgba(0, 0, 0, 0.01),
    0px 130px 78px 0px rgba(0, 0, 0, 0.03), 0px 58px 58px 0px rgba(0, 0, 0, 0.04),
    0px 14px 32px 0px rgba(0, 0, 0, 0.05);

  p,
  h6 {
    padding: 0%;
    margin: 0;
  }
`;

function TwentyTwentyFourOregonPage(props: PageProps) {
  const refVideoDialogue = React.useRef<HTMLDialogElement | null>(null);
  const refVideoPlayer = React.useRef<YouTubePlayer | null>(null);

  React.useEffect(() => {
    if (refVideoDialogue.current) {
      refVideoDialogue.current.addEventListener('close', () => {
        refVideoPlayer.current?.getInternalPlayer().pauseVideo();
      });
      refVideoDialogue.current.addEventListener('click', (e) => {
        if (e.target === refVideoDialogue.current) {
          refVideoDialogue.current?.close();
        }
      });
    }
  });

  React.useEffect(() => {});

  const splideOptions = {
    type: 'loop', // Loop back to the beginning when reaching the end
    perPage: 3, // Number of items visible per page
    perMove: 1, // Move one item at a time
    rewind: false, // Rewind to start when the end is reached
    pagination: false, // Enable pagination dots
    gap: '28px', // Gap between slides
    width: '100%', // Width of the slider
    fixedWidth: '500px', // Fixed width of each slide
    fixedHeight: '200px', // Fixed height of each slide
    arrows: false, // Hide arrows
    trimSpace: true, // Trim space at the edges
    autoScroll: {
      pauseOnHover: true, // Do not pause scrolling when hovering over the carousel
      pauseOnFocus: true, // Do not pause scrolling when the carousel is focused
      rewind: true, // Rewind to start when the end is reached
      speed: 1, // Scrolling speed
    },
  };

  const sliderOptionsMobile = {
    ...splideOptions,
    perPage: 1,
    fixedWidth: '95%',
    fixedHeight: '250px',
    gap: '12px',
  };

  return (
    <StileOregonLayout {...props}>
      <OregonTopSection
        headingContent="Oregon's favorite science curriculum"
        subHeadingContent={
          <p>
            Last year, 22 Oregon districts adopted Stile for their middle schools — that’s nearly a
            third of all adoptions made that year. <br />
            <br />
            Why? Explore the map of Oregon to find out.
          </p>
        }
        coverImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/oregon/map.png"
            alt="cover image"
            placeholder="none"
          />
        }
      >
        <div className="hearts">
          <span
            className="particle"
            style={{
              top: '0',
              left: '1%',
              width: '10px',
              height: '10px',
              animationDelay: '0.8s',
              zIndex: 9999,
            }}
          ></span>
          <span
            className="particle"
            style={{
              top: '0',
              left: '3%',
              width: '10px',
              height: '10px',
              animationDelay: '2.5s',
              zIndex: 9999,
            }}
          ></span>
          <span
            className="particle"
            style={{
              top: '0',
              left: '5%',
              width: '10px',
              height: '10px',
              animationDelay: '3.5s',
              zIndex: 9999,
            }}
          ></span>
          <TestimonailsSection className="desktop">
            <Splide options={splideOptions} extensions={{ AutoScroll }}>
              {testimonials.map((testimonial, index) => (
                <SplideSlide key={index}>
                  <StyledTestimonial {...testimonial} />
                </SplideSlide>
              ))}
            </Splide>

            <Splide
              options={{
                ...splideOptions,
                autoScroll: {
                  ...splideOptions.autoScroll,
                  speed: splideOptions.autoScroll.speed * 1.33,
                },
              }}
              extensions={{ AutoScroll }}
            >
              {testimonials.map((testimonial, index) => (
                <SplideSlide key={index}>
                  <StyledTestimonial {...testimonial} />
                </SplideSlide>
              ))}
            </Splide>
          </TestimonailsSection>

          <TestimonailsSection className="mobile">
            <Splide options={sliderOptionsMobile} extensions={{ AutoScroll }}>
              {testimonials.map((testimonial, index) => (
                <SplideSlide key={index}>
                  <StyledTestimonial {...testimonial} />
                </SplideSlide>
              ))}
            </Splide>

            <Splide
              options={{
                ...sliderOptionsMobile,
                autoScroll: {
                  ...sliderOptionsMobile.autoScroll,
                  speed: sliderOptionsMobile.autoScroll.speed * 1.33,
                },
              }}
              extensions={{ AutoScroll }}
            >
              {testimonials.map((testimonial, index) => (
                <SplideSlide key={index}>
                  <StyledTestimonial {...testimonial} />
                </SplideSlide>
              ))}
            </Splide>
          </TestimonailsSection>
        </div>
      </OregonTopSection>

      <AlignedSection>
        <div className="content">
          <Grid>
            <div className="left">
              <StaticImage
                src="../../../../stile-shared/assets/images/states/oregon/orgeon-mountain.jpeg"
                alt="image of oregon"
              />
              <div className="--callout">
                <strong>95%</strong>
                <span>on the Oregon IMET</span>
              </div>
            </div>
            <div className="right">
              <h2>Aligned and alive with the Oregon Science Standards</h2>
              <div className="--text">
                <p>
                  There&apos;s &apos;alignment.&apos; And then there&apos;s actual alignment. Thanks
                  to Stile&apos;s powerful and customizable platform, we deconstructed our NGSS
                  Middle School curriculum and rebuilt it from the ground up. This means it includes
                  brand-new lessons tailored specifically to meet the Oregon Science Standards.
                  <br />
                  <br />
                  It&apos;s not surprising, then, that we{' '}
                  <strong>scored 95% on the Oregon IMET</strong> (Instructional Materials Evaluation
                  Toolkit) by the Department of Education.
                </p>
              </div>
              <div className="--buttons">
                <Button
                  href="/oregon/standards"
                  size="small"
                  label={
                    <div>
                      <svg
                        style={{ marginRight: 10 }}
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M3.88735 4.51562C3.51309 4.51562 3.20996 4.81875 3.20996 5.19302C3.20996 5.56728 3.51309 5.87041 3.88735 5.87041H6.59691C6.97118 5.87041 7.2743 5.56728 7.2743 5.19302C7.2743 4.81875 6.97118 4.51562 6.59691 4.51562H3.88735Z"
                            fill="#2B2B2B"
                          />
                          <path
                            id="Vector_2"
                            d="M3.88735 8.12866C3.51308 8.12866 3.20996 8.43179 3.20996 8.80605C3.20996 9.18032 3.51308 9.48344 3.88735 9.48344H11.1134C11.4868 9.48344 11.7908 9.18032 11.7908 8.80605C11.7908 8.43179 11.4868 8.12866 11.1134 8.12866H3.88735Z"
                            fill="#2B2B2B"
                          />
                          <path
                            id="Vector_3"
                            d="M3.20996 12.4188C3.20996 12.0446 3.51308 11.7415 3.88735 11.7415H11.1134C11.4868 11.7415 11.7908 12.0446 11.7908 12.4188C11.7908 12.7931 11.4868 13.0962 11.1134 13.0962H3.88735C3.51308 13.0962 3.20996 12.7931 3.20996 12.4188Z"
                            fill="#2B2B2B"
                          />
                          <path
                            id="Vector_4"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.5 2.48348C0.5 1.11177 1.61261 0 2.98434 0H7.65498C8.31375 0 8.9454 0.261635 9.41112 0.727355L13.7726 5.08801C14.2383 5.55456 14.5 6.18623 14.5 6.845V15.1287C14.5 16.5004 13.3874 17.6122 12.0157 17.6122H2.98436C1.61265 17.6122 0.500021 16.5004 0.500021 15.1287L0.5 2.48348ZM2.98434 1.35478C2.3603 1.35478 1.8548 1.86028 1.8548 2.48348V15.1287C1.8548 15.7519 2.3603 16.2574 2.98434 16.2574H12.0156C12.6397 16.2574 13.1452 15.7519 13.1452 15.1287V6.845C13.1452 6.82129 13.1443 6.79759 13.1426 6.77388H10.2096C8.83788 6.77388 7.72611 5.66211 7.72611 4.2904V1.35736C7.7024 1.35566 7.6787 1.35482 7.65499 1.35482L2.98434 1.35478ZM9.08086 2.31245L12.1867 5.41912H10.2095C9.58633 5.41912 9.08083 4.91363 9.08083 4.29043L9.08086 2.31245Z"
                            fill="#2B2B2B"
                          />
                        </g>
                      </svg>
                      Download Stile’s IMET evaluation
                    </div>
                  }
                />
                <Button
                  href="/oregon/standards"
                  size="small"
                  label="View Stile’s alignment to Oregon’s standards"
                />
              </div>
            </div>
          </Grid>
        </div>
      </AlignedSection>

      <HearFromSection>
        <div className="content">
          <Grid>
            <div className="left">
              <h2>Hear from Oregon educators</h2>
              <div className="--text">
                <p>
                  Envisioned as a teacher&apos;s ultimate sidekick, Stile&apos;s technology is
                  designed to put teachers in the driver&apos;s seat. Abby, Jenn, Stephen, and Jim
                  share some of their favorite moments teaching with Stile.
                </p>
              </div>
            </div>
            <button
              className="right"
              onClick={() => {
                refVideoDialogue?.current?.showModal();
                refVideoPlayer.current?.getInternalPlayer()?.playVideo();
              }}
            >
              <StaticImage
                src="../../../../stile-shared/assets/images/states/oregon/video.jpg"
                alt="thumbnail from a video"
              />
              <svg
                className="playButton"
                xmlns="http://www.w3.org/2000/svg"
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
              >
                <circle opacity="0.5" cx="39" cy="39" r="39" fill="white" />
                <path d="M49 39L34 47.6603L34 30.3397L49 39Z" fill="#2B2B2B" />
              </svg>
            </button>
          </Grid>
          <dialog ref={refVideoDialogue}>
            <YouTubePlayer
              ref={refVideoPlayer}
              tabIndex={0}
              url={'https://www.youtube.com/watch?v=7YXQYLq-6tU'}
              width="100%"
              height="100%"
              playing={false}
              playsinline={false}
              controls={true}
              rel={0}
            />
            {/* <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/7YXQYLq-6tU?mute=0&controls=1&origin=https%3A%2F%2Fstileeducation.com&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=3"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe> */}
            <div id="player"></div>
          </dialog>
        </div>
      </HearFromSection>

      <RealWorldSection
        backgroundColor="--stile-green-2"
        headingColor="--stile-grey-8"
        headingContent="Real-world phenomena from Oregon’s backyard"
        textColor="--stile-grey-8"
      >
        <div className="--subheader">
          Many of Stile&apos;s phenomena are drawn from wonders right around the world. After all,
          it&apos;s important that students grow into global citizens. But sometimes, there&apos;s
          nothing more interesting than investigating a phenomena in the place you live.
          Stile&apos;s curriculum for Oregon has a balanced blend of local and global phenomena.
        </div>
        <div className="showcase-content">
          <Slider
            tabs={[
              'Oregon ghost towns',
              'Southern Oregon dam removal',
              'Oregon’s top marine biologist',
              'Oregon’s Lithium find',
            ]}
            headings={[
              'The rise and fall of historic Oregon towns',
              'Reviving ecosystems through dam removal',
              'Marine Conservation with Joleah Lamb',
              'The world’s largest Lithium deposit',
            ]}
            paragraphs={[
              "Students journey back to the 1800s, when Americans headed West in search of gold, settling in Oregon towns. These towns thrived but were soon abandoned, leaving behind Ghost Towns. In Stile's Active Earth unit, students uncover how geoscience led to uneven gold distribution. Pretty neat, huh?",
              'Diving into the fascinating story of removing four dams on the Klamath River, students witness how released sediments breathe new life into habitats and ecosystems. As part of our Energy Conservation unit, this hands-on exploration sharpens their critical thinking and showcases the real-world power of science-driven decisions.',
              "You can't dream it until you see it done. Students discover Assistant Professor Joleah Lamb, a marine biologist from Oregon, who’s uncovering the impacts of human activity on coral ecosystems. Joleah’s story, part of our Human Impacts on Ecosystems unit, shows students just how diverse and exciting a career in science can be.",
              'In 2023, geologists uncovered a massive lithium deposit in McDermitt Caldera, on the Oregon-Nevada border. With up to 40 million metric tons, this discovery was major. As part of our Breaking News unit, students explore why lithium matters, its global impact, and how finds like this shape our future. Talk about groundbreaking!',
            ]}
            images={[
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/states/oregon/slider-1.png"
                alt="feedback"
              />,
              <StaticImage
                key={1}
                src="../../../../stile-shared/assets/images/states/oregon/slider-2.png"
                alt="feedback"
              />,
              <StaticImage
                key={2}
                src="../../../../stile-shared/assets/images/states/oregon/slider-3.png"
                alt="feedback"
              />,
              <StaticImage
                key={3}
                src="../../../../stile-shared/assets/images/stile-pl/pl-slider-4.png"
                alt="feedback"
              />,
            ]}
          />
        </div>
      </RealWorldSection>

      <ScienceTestSection
        headingContent="Prepare your students for the Oregon Science Test"
        backgroundColor="--stile-grey-1"
      >
        <>
          <div className="--subheader">
            The Oregon science test aims to measure students&apos; science knowledge and critical
            thinking ability. Stile&apos;s curriculum precisely scaffolds both of these, and
            provides students with plenty of opportunity to practice and excel on the Oregon Science
            Test.
          </div>
          <div className="--side-by-side">
            <div className="--left">
              <h6 className="h8 --rounded-heading">Oregon Science Test</h6>
              <h6 className="h8">Question</h6>
              <p className="--small">
                Venus and Earth have similar masses, but Venus travels faster and has an orbital
                period of less than one Earth year.
                <br />
                <br />
                Create a model to describe the difference in orbital speed. Draw one arrow for each
                planet to show the magnitude and direction of the force of gravity on each planet.
                Longer arrows represent forces with greater magnitude.
              </p>
              <StaticImage
                src="../../../../stile-shared/assets/images/states/oregon/science-test-example.jpg"
                alt="example of a science test question"
              />
            </div>
            <div className="--right">
              <h6 className="h8 --rounded-heading">Stile Science Test</h6>
              <h6 className="h8">Question</h6>
              <p className="--small">
                Venus and Earth have similar masses, but Venus travels faster and has an orbital
                period of less than one Earth year.
                <br />
                <br />
                Create a model to describe the difference in orbital speed. Draw one arrow for each
                planet to show the magnitude and direction of the force of gravity on each planet.
                Longer arrows represent forces with greater magnitude.
              </p>
              <StaticImage
                src="../../../../stile-shared/assets/images/states/oregon/science-test-example-2.jpg"
                alt="example of a science test question"
              />
            </div>
          </div>
        </>
      </ScienceTestSection>
      <ExpertAssistance>
        <div className="content">
          <div>
            <div className="--left">
              <div className="--headings">
                <h4>Pilot Stile supported by our Oregon-based team</h4>
              </div>
              <div className="--copy">
                <p>
                  Our team of supportive educators are based here in Portland to provide
                  on-the-ground support, whenever you need it.
                </p>
                <Button
                  href="/why-choose-stile/oregon-pilot/"
                  label={
                    <>
                      <span>Learn more about Stile’s supported pilots</span>
                      <Icon src={iconRightArrow} iconSize="fontSize" />
                    </>
                  }
                />
                <Button
                  href="/set-up-trial/"
                  label={
                    <>
                      <span>Start a conversation with us</span>
                    </>
                  }
                />
              </div>
            </div>
            <div className="--right">
              <StaticImage
                src="../../../../stile-shared/assets/images/states/oregon/expert.png"
                alt="Mark Picardo"
                quality={100}
                height={1250}
              />
            </div>
          </div>
        </div>
      </ExpertAssistance>
    </StileOregonLayout>
  );
}

export default TwentyTwentyFourOregonPage;
